<template>
    <div id="template" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex">
            <div class="back flex items-center justify-center mr-auto" @click="back">
                <feather-icon icon="ArrowLeftIcon"></feather-icon>
            </div>
            <div class="title flex items-center uppercase">Settings Website</div>
            <div class="save flex items-center justify-center ml-auto" @click.prevent="save">
                <feather-icon icon="CheckIcon"></feather-icon>
            </div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Email" v-model="form.email"
                        :danger="validation.hasError('form.email')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.email')" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Phone" v-model="form.phone"
                        :danger="validation.hasError('form.phone')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.phone')" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="No WA" v-model="form.whatsapp"
                        :danger="validation.hasError('form.whatsapp')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.whatsapp')" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <label class="vs-input--label">
                        Address
                    </label>
                    <vs-textarea v-model="form.address" />
                </div>
            </div>

            <vs-divider>Day ( Schedule )</vs-divider>
            <div class="vx-row mb-6">
                <div class="vx-col w-1/2">
                    <vs-input class="w-full" type="text" label="Open" v-model="form.dayOpen" />
                </div>
                <div class="vx-col w-1/2">
                    <vs-input class="w-full" type="text" label="Close" v-model="form.dayClose" />
                </div>
            </div>

            <vs-divider>Time ( Schedule )</vs-divider>
            <div class="vx-row mb-6">
                <div class="vx-col w-1/2">
                    <vs-input class="w-full" type="text" label="Open" v-model="form.timeOpen" />
                </div>
                <div class="vx-col w-1/2">
                    <vs-input class="w-full" type="text" label="Close" v-model="form.timeClose" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Facebook URL" v-model="form.facebook" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Instagram URL" v-model="form.instagram" />
                </div>
            </div>

            <div class="vx-row mb-4">
                <div class="vx-col w-full">
                    <vs-button class="w-full" icon-pack="feather" icon="icon-check" @click.prevent="save">
                        Save</vs-button>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-button class="w-full" type="flat" color="dark" @click="back" icon-pack="feather"
                        icon="icon-arrow-left">
                        Back</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import SimpleVueValidation from 'simple-vue-validator'
    const Validator = SimpleVueValidation.Validator

    Vue.use(SimpleVueValidation)
    export default {
        data() {
            return {
                form: {
                    email: '',
                    whatsapp: '',
                    address: '',
                    phone: '',
                    facebook: '',
                    instagram: '',
                    dayOpen: '',
                    dayClose: '',
                    timeOpen: '',
                    timeClose: '',
                },
            }
        },
        validators: {
            'form.email': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).email()
                }
            },
            'form.whatsapp': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value)
                }
            },
            'form.phone': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value)
                }
            },
        },
        mounted() {
            this.loadData();
        },
        methods: {
            loadData() {
                this.getData().then(res => {
                    if (res) {
                        res.map(v => {
                            if (v.key === 'email') {
                                this.form.email = v.value;
                            }
                            if (v.key === 'whatsapp') {
                                this.form.whatsapp = v.value;
                            }
                            if (v.key === 'address') {
                                this.form.address = v.value;
                            }
                            if (v.key === 'phone') {
                                this.form.phone = v.value;
                            }
                            if (v.key === 'facebook') {
                                this.form.facebook = v.value;
                            }
                            if (v.key === 'instagram') {
                                this.form.instagram = v.value;
                            }
                            if (v.key === 'dayOpen') {
                                this.form.dayOpen = v.value;
                            }
                            if (v.key === 'dayClose') {
                                this.form.dayClose = v.value;
                            }
                            if (v.key === 'timeOpen') {
                                this.form.timeOpen = v.value;
                            }
                            if (v.key === 'timeClose') {
                                this.form.timeClose = v.value;
                            }
                        })
                    }
                })
            },
            getData() {
                return new Promise((resolve, reject) => {
                    this.$http({
                            url: 'v1/website',
                            method: 'GET',
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            back() {
                this.$router.go(-1)
            },
            save() {
                this.$validate().then(result => {
                    if (result) {
                        this.actionSave().then(async () => {
                                this.$vs.notify({
                                    title: 'Success!',
                                    text: 'Saved!',
                                    color: 'success',
                                    position: 'top-center'
                                })
                                await this.clearForm();
                                this.validation.reset();
                            })
                            .catch(err => {
                                this.$vs.notify({
                                    title: 'Oops!',
                                    text: err.response ? err.response.data.message :
                                        'Something wrong, ' + err,
                                    color: 'danger',
                                    position: 'top-center'
                                })
                                this.$vs.loading.close()
                            })
                    }
                })
            },
            actionSave() {
                return new Promise((resolve, reject) => {
                    const postData = {
                        data: [{
                                key: 'email',
                                value: this.form.email
                            },
                            {
                                key: 'whatsapp',
                                value: this.form.whatsapp
                            },
                            {
                                key: 'address',
                                value: this.form.address
                            },
                            {
                                key: 'phone',
                                value: this.form.phone
                            },
                            {
                                key: 'facebook',
                                value: this.form.facebook
                            },
                            {
                                key: 'instagram',
                                value: this.form.instagram
                            },
                            {
                                key: 'dayOpen',
                                value: this.form.dayOpen
                            },
                            {
                                key: 'dayClose',
                                value: this.form.dayClose
                            },
                            {
                                key: 'timeOpen',
                                value: this.form.timeOpen
                            },
                            {
                                key: 'timeClose',
                                value: this.form.timeClose
                            },
                        ]
                    }
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/website',
                            method: 'POST',
                            data: postData
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            clearForm() {
                this.back();
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .now {
                font-size: 25px;
                text-align: center;
                margin-top: 20%;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>